import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';


export function bayerInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {

  return next(req)
    .pipe(
      catchError((error: any) => {
        console.log(error);
        if (error?.status === 500) {
          console.log(error);
        }
        return throwError(() => error);

      })
    )
    ;
}
