<router-outlet></router-outlet>

<tour-step-template>
  <ng-template let-step="step">
    <mat-card
      (click)="$event.stopPropagation()"
      [style.width]="step.stepDimensions?.width"
      [style.min-width]="step.stepDimensions?.minWidth"
      [style.max-width]="step.stepDimensions?.maxWidth"
    >
      <mat-card-header>
        <div class="header-group">
          <button mat-icon-button (click)="tourService.end()" class="close">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </mat-card-header>

      <mat-card-content class="mat-body tour-mat-body">
        {{ step.content | translate }}
      </mat-card-content>

      <mat-card-actions [class.no-progress]="!step.showProgress">
        <button
          mat-button
          class="prev"
          [disabled]="!tourService.hasPrev(step)"
          (click)="tourService.prev()"
        >
          {{ "translation.HelpTour.previous" | translate }}
        </button>
        @if (tourService.hasNext(step) && !step.nextOnAnchorClick) {
        <button class="next" (click)="tourService.next()" mat-button>
          {{ "translation.HelpTour.next" | translate }}
          <span class="progress">
            ( {{ tourService.steps.indexOf(step) + 1 }} /
            {{ tourService.steps.length }} )
          </span>
        </button>
        } @if (!tourService.hasNext(step)) {
        <button class="next" mat-button (click)="tourService.end()">
          {{ "translation.HelpTour.end" | translate }}
        </button>
        }
      </mat-card-actions>
    </mat-card>
  </ng-template>
</tour-step-template>
