<!-- <div id="popup" class="popup-container">
    <div class="popup-content">
        <h2>Workflow Solutions //Insights</h2>
        <p>Actionable Intelligence for Enterprise Management</p>
        <p>Welcome to the Workflow Solutions //Insights Injection Intelligence Center, where you will be able to visualize the power of your data.</p>
        <p>Click to explore what //Insights could do for you.</p>
        <p>Unleash the power of your data.</p>
        <button onclick="explore()">Explore >></button>
        <button onclick="skipForNow()">Skip for now >></button>
        <div class="checkbox-container">
            <input type="checkbox" id="dontShowAgain" />
            <label for="dontShowAgain">Don't show again</label>
        </div>
    </div>
</div> -->
<div id="popup" class="popup-container">
  <div class="popup-content">
    <h2>{{ "translation.App.apptitle" | translate }}</h2>
    <!-- new content added as per the PDF given on 16th sept  -->
    <p class="content-new-tour">
      The Workflow Solutions //Insights Contrast Dashboard provides injection
      and contrast metrics that may assist the healthcare provider in managing
      contrast delivery parameters for consistent efficiency and quality while
      providing benchmarking data that may allow for Radiology department goal
      setting.
    </p>
    <!-- pervious content removed as per the PDF given on 16th sept  -->
    <!-- <p>{{ "translation.helptour.text02" | translate }}</p>
    <p>{{ "translation.helptour.text03" | translate }}</p>
    <p>{{ "translation.helptour.text04" | translate }}</p>
    <p>{{ "translation.helptour.text05" | translate }}</p> -->
    <div class="explore">
      <a (click)="explore()">{{ "translation.helptour.text06" | translate }}</a>
    </div>
    <div class="popout-bottom">
      <div class="skipnow">
        <a (click)="skipForNow()">{{
          "translation.helptour.text07" | translate
        }}</a>
      </div>
      <div class="checkbox-container">
        <input
          type="checkbox"
          id="dontShowAgain"
          class="dontShowAgain"
          (click)="dontShowAgain()"
        />
        <label for="dontShowAgain">{{
          "translation.helptour.text08" | translate
        }}</label>
      </div>
    </div>
  </div>
</div>
