import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { environment } from '@environments/environment';
import { catchError, Observable } from 'rxjs';
declare let gigya: any;

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(public router: Router, private authService: AuthService) {}
  canActivate(): Observable<boolean> | boolean {
    return new Observable<boolean>((observer) => {
      gigya.accounts.verifyLogin({
        callback: (response: any) => {
          if (response.errorCode === 0) {
            sessionStorage.setItem(
              'profile',
              JSON.stringify(response?.profile)
            );
            const isAuthorized = JSON.parse(
              sessionStorage.getItem('isAuthorized') as string
            );
            if (!isAuthorized) {
              const {
                apiKey,
                clientId,
                responseType,
                redirect_uri,
                scope,
                issuer_url,
              } = environment;

              const params = {
                client_id: clientId,
                response_type: responseType,
                redirect_uri: redirect_uri,
                scope: scope,
                nonde: Date.now(),
              };

              const url = gigya.utils.URL.addParamsToURL(
                `${issuer_url}/${apiKey}/authorize`,
                params
              );
              this.authService.setAuthorize(true);
              window.location.href = url;
            }

            observer.next(true);
            observer.complete();
          } else {
            this.router.navigate(['/login']);
            observer.next(false);
            observer.complete();
          }
        },
      });
    }).pipe(
      catchError(() => {
        this.router.navigate(['/login']);
        return new Observable<boolean>((observer) => {
          observer.next(false);
          observer.complete();
        });
      })
    );
  }
}
