import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnInit,
} from '@angular/core';
import {
  NavigationEnd,
  Router,
  RouterModule,
  RouterOutlet,
} from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { LanguageService } from '@shared/services/language.service';
import { TourMatMenuModule, TourService } from 'ngx-ui-tour-md-menu';
import { NgIf } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { PopupComponent } from './shared/components/popup/popup.component';
import { filter } from 'rxjs';
import { AuthService } from '@core/services/auth.service';
import { environment } from '@environments/environment';
declare let gigya: any;
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    TranslateModule,
    TourMatMenuModule,
    RouterModule,
    NgIf,
    MatMenuModule,
    MatIcon,
    MatCardModule,
    MatDialogModule,
  ],
  providers: [AuthService],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  constructor(
    private translateService: TranslateService,
    private languageService: LanguageService,
    private router: Router,
    private authService: AuthService,
    public tourService: TourService,
    private dialog: MatDialog
  ) {
    const lang: string = this.languageService.getLanguage();
    this.translateService.setDefaultLang(lang);
  }

  ngOnInit(): void {


    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        const isOpen = JSON.parse(sessionStorage.getItem('isOpen') as string);
        if (
          !JSON.parse(isOpen) &&
          !this.router.url.includes('/login') &&
          !this.router.url.includes('/callback') &&
          !this.router.url.includes('/proxy')
        ) {
          this.dialog.open(PopupComponent);
        }
      });
  }
}
