import { Component, OnInit } from '@angular/core';
// import { CommonTourService } from '@app/shared/services/common-tour-service';

import { MatDialogRef } from '@angular/material/dialog';
import { CommonTourService } from '@app/shared/services/commonTour/common-tour-service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
@Component({
  selector: 'app-popup',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './popup.component.html',
  styleUrl: './popup.component.scss',
})
export class PopupComponent implements OnInit {
  constructor(
    private Commontourservice: CommonTourService,
    private dialogref: MatDialogRef<PopupComponent>
  ) {}
  ngOnInit(): void {}
  elem = document.documentElement;

  explore() {
    this.elem.requestFullscreen;
    this.Commontourservice.startTour();
    this.dialogref.close(false);
  }
  skipForNow() {
    return this.dialogref.close(false);
  }

  // const dialogRef = this.dialog.open(ModalDialogComponent);

  // dialogRef.afterClosed().subscribe(result => {
  //   console.log(`Dialog result: ${result}`);
  // });

  // dontShowAgain(){
  //   if(document.getElementById("dontShowAgain").checked == true){
  //     return 1;
  //   }
  dontShowAgain() {
    const checkbox = document.getElementById(
      'dontShowAgain'
    ) as HTMLInputElement;

    sessionStorage.setItem('isOpen', JSON.stringify(checkbox.checked));
    this.dialogref.close();
  }
}
